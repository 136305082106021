import React from 'react';
import Calculator from './components/Calculator';
import logo from './logo.png';
const App = () => {
	return (
		<>
			<div className="App">
				<img
					src={logo}
					alt="logo"
					style={{
						marginBottom: '2rem',
						maxWidth: '100%',
						objectFit: 'cover',
						marginInline: '1.5rem',
					}}
				/>
				<Calculator />
			</div>
		</>
	);
};

export default App;
