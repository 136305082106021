import React, { useState, useEffect } from 'react';
import DisplayWindow from './DisplayWindow';
import KeysWindow from './KeysWindow';

const Calculator = () => {
	const [expression, setExpression] = useState('');
	const [displayEXP, setDisplayEXP] = useState('');
	const [result, setResult] = useState('0');

	const sciFunc = {
		sin: 'Math.sin',
		cos: 'Math.cos',
		tan: 'Math.tan',
		ln: 'Math.log',
		log: 'Math.log10',
		π: 'Math.PI',
		e: 'Math.E',
		'^': '**',
		'√': 'Math.sqrt',
	};

	useEffect(() => {
		const handleKeyDown = (event) => {
			const key = event.key;
			if (
				!isNaN(key) ||
				['+', '-', '*', '/', '.', '(', ')'].includes(
					key
				)
			) {
				if (key === '*') {
					handleButton('x');
				} else {
					handleButton(key);
				}
			} else if (key === 'Enter') {
				handleButton('=');
			} else if (key === 'Backspace') {
				handleButton('DEL');
			} else if (key.toLowerCase() === 'c') {
				handleButton('AC');
			} else if (key === 's') {
				handleButton('sin');
			} else if (key === 'c') {
				handleButton('cos');
			} else if (key === 't') {
				handleButton('tan');
			} else if (key === 'l') {
				handleButton('log');
			} else if (key === 'p') {
				handleButton('π');
			} else if (key === 'e') {
				handleButton('e');
			} else if (key === '^') {
				handleButton('^');
			} else if (key === 'r') {
				handleButton('√');
			} else if (key === '!') {
				handleButton('!');
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [expression, displayEXP]);

	function calcResult() {
		try {
			let exp = expression.replace(/x/g, '*');
			let compute = eval(exp);
			compute = parseFloat(compute.toFixed(4));
			setResult(compute.toString());
			setExpression('');
			setDisplayEXP(compute.toString());
		} catch (error) {
			setResult('Error');
			setExpression('');
			setDisplayEXP('');
		}
	}

	function handleButton(value) {
		if (value === 'AC') {
			setExpression('');
			setDisplayEXP('');
			setResult('0');
		} else if (value === 'DEL') {
			setDisplayEXP(displayEXP.slice(0, -1));
			setExpression(expression.slice(0, -1));
		} else if (sciFunc.hasOwnProperty(value)) {
			setDisplayEXP(displayEXP + value);
			setExpression(expression + sciFunc[value]);
		} else if (value === '!') {
			const lastNum = extractLastNum(expression);
			if (lastNum != null) {
				const num = parseFloat(lastNum);
				setDisplayEXP(displayEXP + value);
				setExpression(
					expression.replace(
						lastNum,
						factorial(num)
					)
				);
			}
		} else if (value === '=') {
			calcResult();
		} else {
			if (value === 'x') value = 'x';
			setExpression(expression + value);
			setDisplayEXP(displayEXP + value);
		}
	}

	function factorial(n) {
		let result = 1;
		for (let i = 1; i <= n; i++) result *= i;
		return result;
	}

	function extractLastNum(exp) {
		const numbers = exp.match(/\d+/g);
		return numbers ? numbers[numbers.length - 1] : null;
	}

	return (
		<div className="calculator">
			<DisplayWindow
				expression={displayEXP}
				result={result}
			/>
			<KeysWindow handleButton={handleButton} />
		</div>
	);
};

export default Calculator;
